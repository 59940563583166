import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language || 'fr');

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
  };

  useEffect(() => {
    setLanguage(i18n.language);
    document.body.dir = i18n.dir();
  }, [i18n, i18n.dir(), i18n.language]);

  return (
    <div className={i18n.dir() === 'ltr' ? 'selected-left' : 'selected-right'}>
      <select
        // className="p-2 rounded-xl m-2 cursor-pointer duration-300 text-black font-bold"
        // onClick={(e) => changeLanguage((e.target as HTMLInputElement).value)}
        value={language}
        onChange={(e) => changeLanguage((e.target as HTMLSelectElement).value)}
      >
        <option value="fr">FR</option>
        <option value="en">EN</option>
        <option value="ar">AR</option>
      </select>
    </div>
  );
};

export default LanguageSelector;
